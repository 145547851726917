import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let AsaasModule = class AsaasModule extends VuexModule {
    async subcontaConsultar() {
        try {
            const response = await api.asaasSubcontaConsultar(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async subcontaCriar(payload) {
        try {
            const response = await api.asaasSubcontaCriar(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async asaasCobrancaExecutePIX(financeiro_parcela_id) {
        const loadingNotification = { content: 'Gerando cobrança...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.asaasCobrancaExecutePIX(mainStore.token, financeiro_parcela_id);
            mainStore.removeNotification(loadingNotification);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.errors || error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async asaasCobrancaExecutePIXDriveFranqueado(financeiro_parcela_id) {
        const loadingNotification = { content: 'Gerando cobrança...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.asaasCobrancaExecutePIXDriveFranqueado(mainStore.token, financeiro_parcela_id);
            mainStore.removeNotification(loadingNotification);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.errors || error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async asaasCobrancaExecutePIXFranqueadoDrive(financeiro_parcela_id) {
        const loadingNotification = { content: 'Gerando cobrança...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.asaasCobrancaExecutePIXFranqueadoDrive(mainStore.token, financeiro_parcela_id);
            mainStore.removeNotification(loadingNotification);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.errors || error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async asaasBuscarConciliacoes(payload) {
        const loadingNotification = { content: 'Buscando conciliações...', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.asaasBuscarConciliacoes(mainStore.token, payload.dataInicial, payload.dataFinal);
            mainStore.removeNotification(loadingNotification);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail.errors || error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], AsaasModule.prototype, "subcontaConsultar", null);
__decorate([
    Action
], AsaasModule.prototype, "subcontaCriar", null);
__decorate([
    Action
], AsaasModule.prototype, "asaasCobrancaExecutePIX", null);
__decorate([
    Action
], AsaasModule.prototype, "asaasCobrancaExecutePIXDriveFranqueado", null);
__decorate([
    Action
], AsaasModule.prototype, "asaasCobrancaExecutePIXFranqueadoDrive", null);
__decorate([
    Action
], AsaasModule.prototype, "asaasBuscarConciliacoes", null);
AsaasModule = __decorate([
    Module({ name: 'asaas' })
], AsaasModule);
export default AsaasModule;
