import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let HelpdeskModule = class HelpdeskModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.tickets = [];
    }
    setHelpdeskTickets(payload) {
        this.tickets = payload;
    }
    async getMyHelpdeskUser() {
        try {
            const response = await api.getMyHelpdeskUser(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async setHelpdeskClaimTicket(payload) {
        try {
            const response = await api.setHelpdeskClaimTicket(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateHelpdeskTicket(payload) {
        try {
            const response = await api.updateHelpdeskTicket(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getHelpdeskTickets() {
        try {
            const response = await api.getHelpdeskTickets(mainStore.token);
            if (response) {
                this.setHelpdeskTickets(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getHelpdeskTicket(id) {
        try {
            const response = await api.getHelpdeskTicket(mainStore.token, id);
            if (response) {
                this.setHelpdeskTickets(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createHelpdeskTicketLog(payload) {
        try {
            const response = await api.createHelpdeskTicketLog(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createHelpdeskTicketLogAnexo(payload) {
        try {
            const response = await api.createHelpdeskTicketLogAnexo(mainStore.token, payload);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createHelpdeskIssueGitHub(payload) {
        try {
            const response = await api.createHelpdeskIssueGitHub(mainStore.token, payload.ticketId, payload.milestone);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async downloadHelpdeskAnexo(payload) {
        try {
            const response = await api.downloadHelpdeskAnexo(mainStore.token, payload.fileName);
            if (response) {
                return response;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async createHelpdeskTickets(payload) {
        const loadingNotification = { content: 'Criando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.createHelpdeskTickets(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Ticket criado com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], HelpdeskModule.prototype, "setHelpdeskTickets", null);
__decorate([
    Action
], HelpdeskModule.prototype, "getMyHelpdeskUser", null);
__decorate([
    Action
], HelpdeskModule.prototype, "setHelpdeskClaimTicket", null);
__decorate([
    Action
], HelpdeskModule.prototype, "updateHelpdeskTicket", null);
__decorate([
    Action
], HelpdeskModule.prototype, "getHelpdeskTickets", null);
__decorate([
    Action
], HelpdeskModule.prototype, "getHelpdeskTicket", null);
__decorate([
    Action
], HelpdeskModule.prototype, "createHelpdeskTicketLog", null);
__decorate([
    Action
], HelpdeskModule.prototype, "createHelpdeskTicketLogAnexo", null);
__decorate([
    Action
], HelpdeskModule.prototype, "createHelpdeskIssueGitHub", null);
__decorate([
    Action
], HelpdeskModule.prototype, "downloadHelpdeskAnexo", null);
__decorate([
    Action
], HelpdeskModule.prototype, "createHelpdeskTickets", null);
HelpdeskModule = __decorate([
    Module({ name: 'helpdesk' })
], HelpdeskModule);
export default HelpdeskModule;
