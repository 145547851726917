import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let PlugnotasModule = class PlugnotasModule extends VuexModule {
    async plugnotasNfseEmitirFinanceiroParcelaMovimentacao(financeiroParcelaMovimentacaoId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfseEmitirFinanceiroParcelaMovimentacao(mainStore.token, financeiroParcelaMovimentacaoId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Solicitação de emissão criada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfseEmitirFinanceiroParcela(financeiroParcelaId) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.plugnotasNfseEmitirFinanceiroParcela(mainStore.token, financeiroParcelaId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Solicitação de emissão criada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfseCancelar(financeiro_parcela_id) {
        const loadingNotification = { content: 'Efetuando solicitação de cancelamento', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.plugnotasNfseCancelar(mainStore.token, financeiro_parcela_id),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Solicitação efetuada com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfseConsultar(financeiroParcelaMovimentacaoId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfseConsultar(mainStore.token, financeiroParcelaMovimentacaoId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFSe',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfseConsultarFinanceiroParcela(financeiroParcelaId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfseConsultarFinanceiroParcela(mainStore.token, financeiroParcelaId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFSe',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceConsultarFinanceiro(financeiroId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfceConsultarFinanceiro(mainStore.token, financeiroId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFCe',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeEmitirFinanceiro(financeiroId) {
        try {
            const response = await api.plugnotasNfeEmitirFinanceiro(mainStore.token, financeiroId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeReemitirTroca(nfe_id) {
        try {
            const response = await api.plugnotasNfeReemitirTroca(mainStore.token, nfe_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeConsultarFinanceiro(financeiroId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfeConsultarFinanceiro(mainStore.token, financeiroId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFe',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceConsultarFinanceiroParcela(financeiroParcelaId) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfceConsultarFinanceiroParcela(mainStore.token, financeiroParcelaId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consultando NFCe',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfsePdfDownload(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.plugnotasNfsePdfDownload(mainStore.token, protocoloId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result[0];
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfePdfDownloadCce(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.plugnotasNfePdfDownloadCce(mainStore.token, protocoloId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return response;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfePdfDownload(plugnotasId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.plugnotasNfePdfDownload(mainStore.token, plugnotasId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${plugnotasId}.pdf`);
            document.body.appendChild(link);
            link.click();
            return;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeXmlDownload(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfeXmlDownload(mainStore.token, protocoloId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfeXmlDownloadCce(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfeXmlDownloadCce(mainStore.token, protocoloId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfcePdfDownload(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfcePdfDownload(mainStore.token, protocoloId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfseXmlDownload(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.plugnotasNfseXmlDownload(mainStore.token, protocoloId),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceXmlDownload(protocoloId) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasNfceXmlDownload(mainStore.token, protocoloId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasNfceXmlsDownload(plugnotasIds) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.plugnotasNfceXmlsDownload(mainStore.token, plugnotasIds);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Download efetuado!',
                color: 'success',
            });
            return response;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasRelatorioPorCNPJ(payload) {
        const loadingNotification = { content: 'Consultando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasRelatorioPorCNPJ(mainStore.token, payload);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consulta efetuada com sucesso!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasEmpresaConsulta(companyId) {
        const loadingNotification = { content: 'Consultando empresa', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await api.plugnotasEmpresaConsulta(mainStore.token, companyId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Consulta efetuada com sucesso!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            await mainStore.checkApiError(error);
        }
    }
    async atualizarDadosCadastroAPiPlugNotas(plugNotas) {
        try {
            const result = await api.atualizarDadosCadastroAPiPlugNotas(mainStore.token, plugNotas);
            mainStore.addNotification({
                content: 'Salvo com sucesso!',
                color: 'success',
            });
            return result.data;
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail.error.data.fields,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasCnpjConsulta(cnpj) {
        try {
            const result = await api.plugnotasCnpjConsulta(mainStore.token, cnpj);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasCreditoAdquirir(payload) {
        try {
            const result = await api.plugnotasCreditoAdquirir(mainStore.token, payload);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getPlugnotasProdutosStatus() {
        try {
            const result = await api.getPlugnotasProdutosStatus(mainStore.token);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasCreditoHistoricoAquisicao() {
        try {
            const result = await api.plugnotasCreditoHistoricoAquisicao(mainStore.token);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasBuscaCertificado(payload) {
        try {
            const result = await api.plugnotasBuscaCertificado(mainStore.token, payload.certificadoId, payload.companyId);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasResumoEmissaoNotas() {
        try {
            const result = await api.plugnotasResumoEmissaoNotas(mainStore.token);
            return result.data;
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async plugnotasVerificaIntegracao() {
        try {
            const result = await api.plugnotasVerificaIntegracao(mainStore.token);
            return result.data;
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail.error.data.fields,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async gerandoArquivoXmlPeriodo(payload) {
        const loadingNotification = { content: 'Baixando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.gerandoArquivoXmlPeriodo(mainStore.token, payload.tipoNota, payload.dataInicial, payload.dataFinal);
            if (response) {
                if (!response.data) {
                    mainStore.removeNotification(loadingNotification);
                    mainStore.addNotification({
                        content: 'Não foi encontrado notas emitidas nesse periodo!',
                        color: 'error',
                    });
                }
                else {
                    mainStore.removeNotification(loadingNotification);
                    mainStore.addNotification({
                        content: 'Download efetuado com sucesso!',
                        color: 'success',
                    });
                }
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async enviarCartaCorrecao(payload) {
        try {
            return await api.enviarCartaCorrecao(mainStore.token, payload.plugnotasId, payload.textoParaCorrecao);
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseEmitirFinanceiroParcelaMovimentacao", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseEmitirFinanceiroParcela", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseCancelar", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseConsultar", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseConsultarFinanceiroParcela", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfceConsultarFinanceiro", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfeEmitirFinanceiro", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfeReemitirTroca", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfeConsultarFinanceiro", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfceConsultarFinanceiroParcela", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfsePdfDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfePdfDownloadCce", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfePdfDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfeXmlDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfeXmlDownloadCce", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfcePdfDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfseXmlDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfceXmlDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasNfceXmlsDownload", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasRelatorioPorCNPJ", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasEmpresaConsulta", null);
__decorate([
    Action
], PlugnotasModule.prototype, "atualizarDadosCadastroAPiPlugNotas", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasCnpjConsulta", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasCreditoAdquirir", null);
__decorate([
    Action
], PlugnotasModule.prototype, "getPlugnotasProdutosStatus", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasCreditoHistoricoAquisicao", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasBuscaCertificado", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasResumoEmissaoNotas", null);
__decorate([
    Action
], PlugnotasModule.prototype, "plugnotasVerificaIntegracao", null);
__decorate([
    Action
], PlugnotasModule.prototype, "gerandoArquivoXmlPeriodo", null);
__decorate([
    Action
], PlugnotasModule.prototype, "enviarCartaCorrecao", null);
PlugnotasModule = __decorate([
    Module({ name: 'plugnotas' })
], PlugnotasModule);
export default PlugnotasModule;
