import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let CursoModule = class CursoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.cursos = [];
        this.cursosEtapas = [];
    }
    setCursos(payload) {
        this.cursos = payload;
    }
    setCursosEtapas(payload) {
        this.cursosEtapas = payload;
    }
    async getCursos() {
        if (this.cursos.length > 0 && this.cursos[0].escola_id === mainStore.userProfile.company.escola_id) {
            return;
        }
        try {
            const response = await api.getCursos(mainStore.token);
            if (response) {
                this.setCursos(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContratoCursos() {
        if (this.cursos.length > 0 && this.cursos[0].escola_id === mainStore.userProfile.company.escola_id) {
            return this.cursos;
        }
        try {
            const response = await api.getCursos(mainStore.token);
            if (response && response.data) {
                this.setCursos(response.data);
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getCursosEtapas() {
        if (this.cursosEtapas.length > 0 && this.cursos[0].escola_id === mainStore.userProfile.company.escola_id) {
            return;
        }
        try {
            const response = await api.getCursosEtapas(mainStore.token);
            if (response) {
                this.setCursosEtapas(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], CursoModule.prototype, "setCursos", null);
__decorate([
    Mutation
], CursoModule.prototype, "setCursosEtapas", null);
__decorate([
    Action
], CursoModule.prototype, "getCursos", null);
__decorate([
    Action
], CursoModule.prototype, "getContratoCursos", null);
__decorate([
    Action
], CursoModule.prototype, "getCursosEtapas", null);
CursoModule = __decorate([
    Module({ name: 'curso' })
], CursoModule);
export default CursoModule;
