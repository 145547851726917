import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let AlunoModule = class AlunoModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.alunos = [];
    }
    setAlunos(payload) {
        this.alunos = payload;
    }
    async createAluno(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno criado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async imprimirBoasVindas(alunoId) {
        try {
            const response = await api.imprimirBoasVindas(mainStore.token, alunoId);
            if (response) {
                // @ts-ignore
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async updateAluno(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateAluno(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno atualizado com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async deleteAluno(alunoId) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.deleteAluno(mainStore.token, alunoId);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Aluno excluído com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
            return false;
        }
    }
    async exluirAlunoContratoFinanceiro(payload) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            await api.exluirAlunoContratoFinanceiro(mainStore.token, payload.company_id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Alunos excluídos com sucesso',
                color: 'success',
            });
            return true;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
            return false;
        }
    }
    async getAlunos(queryIn) {
        try {
            const response = await api.getAlunos(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoContratoPDF(contratoId) {
        try {
            const response = await api.getAlunoContratoPDF(mainStore.token, contratoId);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getServicoCarne(payload) {
        const loadingNotification = { content: 'Gerando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.getServicoCarne(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Carnê gerado com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getTransportes(id) {
        try {
            const response = await api.getAlunoTransporte(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getIndicacoes(id) {
        try {
            const response = await api.getAlunoIndicacoes(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunosTableColunas() {
        try {
            const response = await api.getAlunosTableColunas(mainStore.token);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getPedagogico(id) {
        try {
            const response = await api.getPedagogico(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getPedagogicoTurmas(id) {
        try {
            const response = await api.getPedagogicoTurmas(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getBoletim(id) {
        try {
            const response = await api.getBoletim(mainStore.token, id);
            if (response) {
                return response;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoFinanceiro(id) {
        try {
            const response = await api.getAlunoFinanceiro(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async previewFileAlunosData(formData) {
        try {
            const response = await api.previewFileAlunosData(mainStore.token, formData);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async importAlunosTemplateData(alunosIn) {
        const loadingNotification = { content: 'Importando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.importAlunosTemplateData(mainStore.token, alunosIn);
            if (response) {
                mainStore.removeNotification(loadingNotification);
                mainStore.addNotification({
                    content: 'Dados importado com sucesso',
                    color: 'success',
                });
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAluno(id) {
        try {
            const response = await api.getAluno(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoByCPF(cpf) {
        try {
            const response = await api.getAlunoByCPF(mainStore.token, cpf);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getAlunoChecarOrdemFinanceiro(id) {
        try {
            const response = await api.getAlunoChecarOrdemFinanceiro(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async alunoArrumaOrdemFinanceiro(id) {
        try {
            const response = await api.alunoArrumaOrdemFinanceiro(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getLivrosAlunosMASTER(payload) {
        try {
            const response = await api.getLivrosAlunosMASTER(mainStore.token, payload.nomeAlunoResponsavel, payload.dataInicial, payload.dataFinal, payload.status_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getAniversariantesQuery(queryIn) {
        try {
            const response = await api.getAniversariantesQuery(mainStore.token, queryIn);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getReposicoesConcluidas(payload) {
        try {
            const response = await api.getReposicoesConcluidas(mainStore.token, payload.aluno_id, payload.turma_id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
};
__decorate([
    Mutation
], AlunoModule.prototype, "setAlunos", null);
__decorate([
    Action
], AlunoModule.prototype, "createAluno", null);
__decorate([
    Action
], AlunoModule.prototype, "imprimirBoasVindas", null);
__decorate([
    Action
], AlunoModule.prototype, "updateAluno", null);
__decorate([
    Action
], AlunoModule.prototype, "deleteAluno", null);
__decorate([
    Action
], AlunoModule.prototype, "exluirAlunoContratoFinanceiro", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunos", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunoContratoPDF", null);
__decorate([
    Action
], AlunoModule.prototype, "getServicoCarne", null);
__decorate([
    Action
], AlunoModule.prototype, "getTransportes", null);
__decorate([
    Action
], AlunoModule.prototype, "getIndicacoes", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunosTableColunas", null);
__decorate([
    Action
], AlunoModule.prototype, "getPedagogico", null);
__decorate([
    Action
], AlunoModule.prototype, "getPedagogicoTurmas", null);
__decorate([
    Action
], AlunoModule.prototype, "getBoletim", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunoFinanceiro", null);
__decorate([
    Action
], AlunoModule.prototype, "previewFileAlunosData", null);
__decorate([
    Action
], AlunoModule.prototype, "importAlunosTemplateData", null);
__decorate([
    Action
], AlunoModule.prototype, "getAluno", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunoByCPF", null);
__decorate([
    Action
], AlunoModule.prototype, "getAlunoChecarOrdemFinanceiro", null);
__decorate([
    Action
], AlunoModule.prototype, "alunoArrumaOrdemFinanceiro", null);
__decorate([
    Action
], AlunoModule.prototype, "getLivrosAlunosMASTER", null);
__decorate([
    Action
], AlunoModule.prototype, "getAniversariantesQuery", null);
__decorate([
    Action
], AlunoModule.prototype, "getReposicoesConcluidas", null);
AlunoModule = __decorate([
    Module({ name: 'aluno' })
], AlunoModule);
export default AlunoModule;
