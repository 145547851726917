import { __decorate } from "tslib";
import { api } from '@/api';
import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators';
import { mainStore } from '@/utils/store-accessor';
let FinanceiroModule = class FinanceiroModule extends VuexModule {
    constructor() {
        super(...arguments);
        this.contasPagar = [];
        this.contasReceber = [];
        this.financeirosMateriaisDidatico = [];
        this.financeirosMateriaisDidaticoNFe = [];
    }
    setContasReceber(payload) {
        this.contasReceber = payload;
    }
    setContasPagar(payload) {
        this.contasPagar = payload;
    }
    setFinanceirosMateriaisDidatico(payload) {
        this.financeirosMateriaisDidatico = payload;
    }
    setFinanceirosMateriaisDidaticoNFe(payload) {
        this.financeirosMateriaisDidaticoNFe = payload;
    }
    async createFinanceiro(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiro(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta a Receber criada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async createFinanceiroComplete(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.createFinanceiroComplete(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta a Receber criada com sucesso',
                color: 'success',
            });
            return result[0].data;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async updateFinanceiro(payload) {
        const loadingNotification = { content: 'Salvando', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const result = await Promise.all([
                api.updateFinanceiro(mainStore.token, payload),
                await new Promise((resolve) => setTimeout(() => resolve(), 500)),
            ]);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Conta a receber atualizada com sucesso',
                color: 'success',
            });
            return result[0].data.id;
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async getContasReceber() {
        try {
            const response = await api.getFinanceiroContasReceber(mainStore.token);
            if (response) {
                this.setContasReceber(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getContasPagar() {
        try {
            const response = await api.getFinanceiroContasPagar(mainStore.token);
            if (response) {
                this.setContasPagar(response.data);
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceiro(id) {
        try {
            const response = await api.getFinanceiro(mainStore.token, id);
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async getFinanceirosMaterialDidatico(payload) {
        try {
            const response = await api.getFinanceirosMaterialDidatico(mainStore.token, payload.dataInicial, payload.dataFinal, payload.tipoDocumento, payload.tipoPesquisa);
            if (response) {
                if (payload.tipoDocumento === 2) {
                    this.setFinanceirosMateriaisDidatico(response.data);
                }
                else if (payload.tipoDocumento === 3) {
                    this.setFinanceirosMateriaisDidaticoNFe(response.data);
                }
            }
        }
        catch (error) {
            await mainStore.checkApiError(error);
        }
    }
    async deleteFinanceiro(id) {
        const loadingNotification = { content: 'Excluindo', showProgress: true };
        try {
            mainStore.addNotification(loadingNotification);
            const response = await api.deleteFinanceiro(mainStore.token, id);
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: 'Financeiro excluído com sucesso',
                color: 'success',
            });
            if (response) {
                return response.data;
            }
        }
        catch (error) {
            mainStore.removeNotification(loadingNotification);
            mainStore.addNotification({
                content: error.response.data.detail,
                color: 'error',
            });
            await mainStore.checkApiError(error);
        }
    }
    async atualizaStatusPlugnotas(item) {
        let items = this.financeirosMateriaisDidatico;
        let index = items.findIndex((nota) => nota.id === item.id);
        if (index >= 0) {
            items[index].plugnotas_status = item.plugnotas_status;
            this.setFinanceirosMateriaisDidatico(items);
            return;
        }
        items = this.financeirosMateriaisDidaticoNFe;
        index = items.findIndex((nota) => nota.id === item.id);
        if (index >= 0) {
            items[index].plugnotas_status = item.plugnotas_status;
            this.setFinanceirosMateriaisDidaticoNFe(items);
        }
    }
};
__decorate([
    Mutation
], FinanceiroModule.prototype, "setContasReceber", null);
__decorate([
    Mutation
], FinanceiroModule.prototype, "setContasPagar", null);
__decorate([
    Mutation
], FinanceiroModule.prototype, "setFinanceirosMateriaisDidatico", null);
__decorate([
    Mutation
], FinanceiroModule.prototype, "setFinanceirosMateriaisDidaticoNFe", null);
__decorate([
    Action
], FinanceiroModule.prototype, "createFinanceiro", null);
__decorate([
    Action
], FinanceiroModule.prototype, "createFinanceiroComplete", null);
__decorate([
    Action
], FinanceiroModule.prototype, "updateFinanceiro", null);
__decorate([
    Action
], FinanceiroModule.prototype, "getContasReceber", null);
__decorate([
    Action
], FinanceiroModule.prototype, "getContasPagar", null);
__decorate([
    Action
], FinanceiroModule.prototype, "getFinanceiro", null);
__decorate([
    Action
], FinanceiroModule.prototype, "getFinanceirosMaterialDidatico", null);
__decorate([
    Action
], FinanceiroModule.prototype, "deleteFinanceiro", null);
__decorate([
    Action
], FinanceiroModule.prototype, "atualizaStatusPlugnotas", null);
FinanceiroModule = __decorate([
    Module({ name: 'financeiro' })
], FinanceiroModule);
export default FinanceiroModule;
